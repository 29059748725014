import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { TumekeJSModule, EAdapterNames } from "@kernel";
import { IStorageAdapter } from "@kernel-adapters/storage";
import { NotificationManager } from "@kernel-helpers/react-notifications";
import {
  loginUser,
  loginServerHelper,
  enterMultifactorCode,
  registerUser,
  checkTrialStatus,
  setDecryptedAESKeysRequest,
  enteredPasswordRequest,
  forgotPassword,
  forgotPasswordConfirm,
  resetPassword,
  updateCompanySettings,
  updateUserSettings,
  setCompanyData,
  logoutUser,
} from "@kernel-store/auth/thunk";

export enum ERegisterStatus {
  Default = "",
  Init = "init",
  CheckCompany = "checkcompany",
  CongitoRegister = "cognitoregister",
  CongitoLogin = "cognitologin",
  VirginInit = "virgilinit",
  VirgilCreateUser = "evirgilcreateuser",
  CreateUser = "createuser",
  CreateCompany = "createcompany",
  JoinCompany = "joincompany",
  AddingToGroup = "addingtogroup",
  Success = "success",
  Error = "error",
}

const defaultError = "Some error, please try again later";

const getUnvisited = (metadata: any[]): any[] => {
  const arr = metadata.filter((m) => !m.visited);
  return arr;
};

const visit = (node: any, newMetadata: any[], metadata: any[]): void => {
  if (node.visited) {
    return;
  }
  const newNode = node;
  newNode.temp = true;
  if (node.parent_field_id != null) {
    const parentNode = metadata.find((m) => m.id === node.parent_field_id);
    if (parentNode) {
      visit(parentNode, newMetadata, metadata);
    }
  }
  newNode.temp = false;
  newNode.visited = true;
  newMetadata.push(node);
};

type TMetadataTypeObject = {
  id: number;
  name: string;
  options: {
    id: number;
    name: string;
    parent_option_id: number | null;
    key?: number;
    included?: boolean;
  }[];
  parent_field_id: number | null;
  type: string;
};

type TMetadataTypeArray = [
  string,
  number,
  number | null,
  {
    label: string;
    value: number;
    key: number;
    parent_option_id: number | null;
    included?: boolean;
  }[],
];

export const sortMetadataObject = (
  metadata: TMetadataTypeObject[],
): TMetadataTypeObject[] => {
  const oldMetadata = metadata.map((metadataObj) => ({
    data: {
      ...metadataObj,
      options: (metadataObj.options || []).map((option: any) => ({
        ...option,
      })),
    },
    visited: false,
  }));
  const newMetadata: typeof oldMetadata = [];
  const firstItem = oldMetadata.find(
    (m) =>
      m.data.name === "Assessment Version" || m.data.name === "Attempt Number",
  );
  if (firstItem) {
    newMetadata.push(firstItem);
    newMetadata[0].visited = true;
  }
  let unvisited = getUnvisited(oldMetadata);
  while (unvisited.length > 0) {
    const node = unvisited[0];
    visit(node, newMetadata, oldMetadata);
    unvisited = getUnvisited(oldMetadata);
  }
  newMetadata.forEach((field) => {
    field.data.options.sort((a, b) => {
      if (field.data.name === "Assessment Version") {
        return a.id - b.id;
      }
      if (a.name > b.name) {
        return 1;
      }
      if (b.name > a.name) {
        return -1;
      }
      return 0;
    });
  });
  return newMetadata.map((metadataObj) => metadataObj.data);
};

export const sortMetadataArray = (
  metadata: TMetadataTypeArray[],
): TMetadataTypeArray[] => {
  const oldMetadata = metadata.map((metadataArr) => ({
    id: metadataArr[1],
    name: metadataArr[0],
    options: (metadataArr[3] || []).map((option) => ({
      id: option.value,
      name: option.label,
      parent_option_id: option.parent_option_id,
      key: option.key,
      included: option.included,
    })),
    parent_field_id: metadataArr[2],
    type: "select",
  })) as TMetadataTypeObject[];
  const sortedMetadataObject = sortMetadataObject(oldMetadata);
  return sortedMetadataObject.map((metadataObj) => [
    metadataObj.name,
    metadataObj.id,
    metadataObj.parent_field_id,
    metadataObj.options.map((option) => ({
      label: option.name,
      value: option.id,
      key: option.key!,
      parent_option_id: option.parent_option_id,
      included: option.included,
    })),
  ]);
};

export interface AuthReduxState {
  user: any;
  company: any;
  multifactorStatus: string;
  multifactorDestination: string;
  forgotPasswordStatus: string;
  fpConfirmSuccess: boolean;
  fpRequestSuccess: boolean;
  decryptingKeys: boolean;
  resetPasswordCode: string;
  loading: boolean;
  error: string;
  userRole: string;
  passwordModalVisible: boolean;
  passwordModalError: string;
  refresh: boolean;
  resetPasswordSuccess: boolean;
  treeFieldNamePlural: string | null;
  treeFieldName: string | null;
  registerStatus: ERegisterStatus;
  companySettingsLoading: boolean;
  userSettingsLoading: boolean;
}

export const authInitialState: AuthReduxState = {
  user: null,
  company: {},
  multifactorStatus: "OFF",
  multifactorDestination: "",
  forgotPasswordStatus: "INITIAL",
  fpConfirmSuccess: false,
  fpRequestSuccess: false,
  decryptingKeys: false,
  resetPasswordCode: "",
  loading: false,
  error: "",
  userRole: "",
  passwordModalVisible: false,
  passwordModalError: "",
  refresh: false, // used to refresh the "Verfiying identity.." text on submit page
  resetPasswordSuccess: false,
  treeFieldNamePlural: null,
  treeFieldName: null,
  registerStatus: ERegisterStatus.Default,
  companySettingsLoading: false,
  userSettingsLoading: false,
};

const authSlice = createSlice({
  name: "auth",
  initialState: authInitialState,
  reducers: {
    setMultifactorStatus: (
      state,
      action: PayloadAction<{
        status: string;
        destination: string;
      }>,
    ) => {
      state.multifactorStatus = action.payload.status;
      state.multifactorDestination = action.payload.destination;
      state.loading =
        action.payload.status === "SENT_CODE" ? false : state.loading;
    },
    loginUserSuccess: (
      state,
      action: PayloadAction<{ user: any; company: any }>,
    ) => {
      state.loading = false;
      state.user = action.payload.user;
      state.company = action.payload.company;
      state.error = "";
    },
    loginUserError: (state, action: PayloadAction<{ message: string }>) => {
      const stogareAdapter = TumekeJSModule.get(
        EAdapterNames.Storage,
      ) as IStorageAdapter;
      stogareAdapter.removeItem("email");
      stogareAdapter.removeItem("password");
      NotificationManager.warning(
        action.payload.message,
        "Login error",
        5000,
        null,
        null,
        "",
      );
      state.loading = false;
      state.error = action.payload.message;
    },
    registerUserStatus: (
      state,
      action: PayloadAction<{ status: ERegisterStatus }>,
    ) => {
      state.registerStatus = action.payload.status;
    },
    virgilInitError: (state) => {
      state.user.virgilError = true;
      state.refresh = !state.refresh;
    },
    enterPasswordError: (
      state,
      action: PayloadAction<{ errorMsg: string }>,
    ) => {
      state.passwordModalError = action.payload.errorMsg;
    },
    askForPasswordShowModal: (state) => {
      state.passwordModalVisible = true;
    },
    cancelAskPassword: (state) => {
      state.passwordModalVisible = false;
    },
    increaseCompanyUserCount: (
      state,
      action: PayloadAction<{ count: number }>,
    ) => {
      state.company.user_count += action.payload.count;
    },
    decreaseCompanyUserCount: (
      state,
      action: PayloadAction<{ count: number }>,
    ) => {
      state.company.user_count -= action.payload.count;
    },
    updateCompanyBaselineTaskSuccess: (
      state,
      action: PayloadAction<{ id: number }>,
    ) => {
      let isBaselineTask = false;
      const baselineTasks = (state.company.baseline_tasks || []).map(
        (baselineTask: any) => {
          if (baselineTask.id === action.payload.id) {
            isBaselineTask = true;
            return action.payload;
          }
          return baselineTask;
        },
      );
      if (!isBaselineTask) {
        baselineTasks.push(action.payload);
      }
      state.company.baseline_tasks = baselineTasks;
    },
    deleteCompanyBaselineTaskSuccess: (
      state,
      action: PayloadAction<{ id: number }>,
    ) => {
      const baselineTasks = (state.company.baseline_tasks || []).filter(
        (baselineTask: any) => {
          if (baselineTask.id === action.payload.id) {
            return false;
          }
          return true;
        },
      );
      state.company.baseline_tasks = baselineTasks;
    },
    setDecryptedAESKeysExternal: (
      state,
      action: PayloadAction<{
        aesKeys: { [key: string]: string };
        callback: () => void;
      }>,
    ) => {
      const { aesKeys, callback } = action.payload;
      if (!state.user) {
        state.user = {};
      }
      state.user.decryptedAESKeys = aesKeys;
      if (callback) {
        console.log("Calling callback");
        callback();
      }
    },
    setForgotPasswordStatus: (
      state,
      action: PayloadAction<{ status: string }>,
    ) => {
      state.forgotPasswordStatus = action.payload.status;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(checkTrialStatus.fulfilled, () => {
      console.log("SUCCESS checkTrialStatus");
    });
    builder.addCase(setDecryptedAESKeysRequest.pending, (state) => {
      state.decryptingKeys = true;
    });
    builder.addCase(setDecryptedAESKeysRequest.fulfilled, (state, action) => {
      state.decryptingKeys = false;
      state.user.decryptedAESKeys = action.payload.aesKeys;
    });
    builder.addCase(setDecryptedAESKeysRequest.rejected, (state) => {
      state.decryptingKeys = false;
    });
    builder.addCase(enteredPasswordRequest.fulfilled, (state, action) => {
      state.decryptingKeys = false;
      state.user.decryptedAESKeys = action.payload.aesKeys;
    });
    builder.addCase(forgotPassword.pending, (state) => {
      state.loading = true;
      state.fpRequestSuccess = false;
      state.error = "";
    });
    builder.addCase(forgotPassword.fulfilled, (state, action) => {
      state.loading = false;
      state.fpRequestSuccess = true;
      state.forgotPasswordStatus = action.payload;
      state.error = "";
    });
    builder.addCase(forgotPassword.rejected, (state, action) => {
      state.loading = false;
      state.fpRequestSuccess = false;
      state.fpConfirmSuccess = false;
      state.error = action.error?.message || defaultError;
    });
    builder.addCase(forgotPasswordConfirm.pending, (state) => {
      state.loading = true;
      state.fpConfirmSuccess = false;
      state.error = "";
    });
    builder.addCase(forgotPasswordConfirm.fulfilled, (state, action) => {
      state.loading = false;
      state.fpConfirmSuccess = true;
      state.forgotPasswordStatus = action.payload;
      state.error = "";
    });
    builder.addCase(forgotPasswordConfirm.rejected, (state, action) => {
      state.loading = false;
      state.fpRequestSuccess = false;
      state.fpConfirmSuccess = false;
      state.error = action.error?.message || defaultError;
    });
    builder.addCase(resetPassword.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(resetPassword.fulfilled, (state) => {
      state.loading = false;
      state.resetPasswordSuccess = true;
      state.error = "";
    });
    builder.addCase(resetPassword.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error?.message || defaultError;
    });
    builder.addCase(updateCompanySettings.pending, (state) => {
      state.companySettingsLoading = true;
    });
    builder.addCase(updateCompanySettings.fulfilled, (state, action) => {
      if (action.payload) {
        state.company = {
          ...state.company,
          ...action.payload,
        };
      }
      state.companySettingsLoading = false;
      state.error = "";
    });
    builder.addCase(updateCompanySettings.rejected, (state, action) => {
      state.companySettingsLoading = false;
      state.error = action.error?.message || defaultError;
    });
    builder.addCase(updateUserSettings.pending, (state) => {
      state.userSettingsLoading = true;
    });
    builder.addCase(updateUserSettings.fulfilled, (state, action) => {
      if (action.payload) {
        state.user = {
          ...state.user,
          ...action.payload,
        };
      }
      state.userSettingsLoading = false;
      state.error = "";
    });
    builder.addCase(updateUserSettings.rejected, (state, action) => {
      state.userSettingsLoading = false;
      state.error = action.error?.message || defaultError;
    });
    builder.addCase(setCompanyData.pending, (state, action) => {
      const { metadata, ...otherData } = action.meta.arg;
      if (metadata) {
        const metadataSorted = sortMetadataObject(metadata);
        state.company.metadata = metadataSorted;
      }
      const companyKeys = Object.keys(otherData) as string[];
      companyKeys.forEach((companyKey) => {
        state.company[companyKey] = otherData[companyKey];
      });
    });
    builder.addCase(loginUser.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(loginServerHelper.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(loginServerHelper.fulfilled, (state, action) => {
      state.loading = false;
      state.user = action.payload.user;
      state.company = action.payload.company;
      state.error = "";
    });
    builder.addCase(loginServerHelper.rejected, (state, action) => {
      NotificationManager.warning(
        action.error?.message || defaultError,
        "Login error",
        5000,
        null,
        null,
        "",
      );
      state.loading = false;
      state.error = action.error?.message || defaultError;
    });
    builder.addCase(enterMultifactorCode.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(registerUser.pending, (state) => {
      state.loading = true;
      state.error = "";
      state.registerStatus = ERegisterStatus.Init;
    });
    builder.addCase(registerUser.fulfilled, (state, action) => {
      state.loading = false;
      state.error = "";
      state.registerStatus = ERegisterStatus.Success;
      if (action.payload.user) {
        state.user = action.payload.user;
      }
      if (action.payload.company) {
        let { metadata } = action.payload.company;
        metadata = metadata.map((field: any) => {
          field.options.sort((a: any, b: any) => a.id - b.id);
          return field;
        });
        const companyObj = {
          ...action.payload.company,
          ...metadata,
        };
        state.company = companyObj;
      }
    });
    builder.addCase(registerUser.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error?.message || defaultError;
      state.registerStatus = ERegisterStatus.Error;
    });
    builder.addCase(logoutUser.pending, (state) => {
      const initialStateKeys = Object.keys(
        authInitialState,
      ) as (keyof AuthReduxState)[];
      initialStateKeys.forEach((key) => {
        Object.assign(state, { [key]: authInitialState[key] });
      });
      state.user = null;
      state.company = null;
      state.treeFieldNamePlural = null;
      state.treeFieldName = null;
    });
  },
});

export const {
  setMultifactorStatus,
  loginUserSuccess,
  loginUserError,
  registerUserStatus,
  virgilInitError,
  enterPasswordError,
  askForPasswordShowModal,
  cancelAskPassword,
  increaseCompanyUserCount,
  decreaseCompanyUserCount,
  updateCompanyBaselineTaskSuccess,
  deleteCompanyBaselineTaskSuccess,
  setDecryptedAESKeysExternal,
  setForgotPasswordStatus,
} = authSlice.actions;

export default authSlice.reducer;
